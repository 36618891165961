import { configureStore } from '@reduxjs/toolkit'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import reducer from './actions'
import locale from './locale'
import { theatresSlice } from './features/theatresDetails'
import { cplApiSlice } from './features/compositions'
import { movieSlice } from './features/movies'
import { usersSlice } from './features/users'

export const store = configureStore({
         reducer: reducer,
         middleware: getDefaultMiddleware =>
           getDefaultMiddleware({
             serializableCheck: false,
             immutableCheck: false
           }).concat(
             theatresSlice.middleware,
             cplApiSlice.middleware,
             movieSlice.middleware,
             usersSlice.middleware
           ),
         devTools: true
       })

syncTranslationWithStore(store)
store.dispatch(loadTranslations(locale))
store.dispatch(setLocale('en'))

export default store
