import { createApi } from '@reduxjs/toolkit/query/react'
import qwUser from '../common/client/qwUser'

const userBaseQuery = async ({ userId }) => {
  try {
    const result = await qwUser.get(`/holdover-kdms/user-info?userId=${userId}`)
    if (result.data?.error) {
      return { error: result.data.error }
    }
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError
    if (err.response?.status === 401) {
      return { error: { status: 401, data: 'Unauthorized access' } }
    }
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export const usersSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery: userBaseQuery,
  serializeQueryArgs: args => args.queryArgs,
  keepUnusedDataFor: 28800,
  endpoints: builder => ({
    fetchUser: builder.query({
      query: userId => {
        return { userId }
      }
    })
  })
})

export const { useFetchUserQuery } = usersSlice
