import { createApi } from '@reduxjs/toolkit/query/react'
import qwDimensions from '../common/client/qwDimensions'

const theatreBaseQuery = async ({ id }) => {
  try {
    const result = await qwDimensions.get(`v1/facilities/${id}`)
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export const theatresSlice = createApi({
  reducerPath: 'theatresApi',
  baseQuery: theatreBaseQuery,
  serializeQueryArgs: args => args.queryArgs,
  keepUnusedDataFor: 28800,
  endpoints: builder => ({
    fetchTheatre: builder.query({
      query: id => {
        return { id }
      }
    })
  })
})

export const { useFetchTheatreQuery } = theatresSlice
