import { i18nReducer } from 'react-redux-i18n'
import { combineReducers } from 'redux'

import app from './app'
import compositionFilters from './compositionFilters'
import compositions from './compositions'
import modal from './modal'
import theatres from './theatres'
import theatreFilter from './theatreFilter'
import inboxFilters from './inboxFilters'
import user from './user'
import appliances from './appliances'
import users from './users'
import groups from './groups'
import facilityDevices from './facilityDevices'
import { theatresSlice } from '../features/theatresDetails'
import { cplApiSlice } from '../features/compositions'
import { movieSlice } from '../features/movies'
import { usersSlice } from '../features/users'

/* export actions */
export * from './theatres'
export * from './compositions'
export * from './modal'
export * from './user'
export * from './compositionFilters'
export * from './app'
export * from './appliances'
export * from './users'
export * from './groups'
export * from './theatreFilter'
export * from './inboxFilters'
export * from './facilityDevices'

export default combineReducers({
  app,
  theatres,
  compositions,
  modal,
  user,
  compositionFilters,
  appliances,
  i18n: i18nReducer,
  users,
  groups,
  theatreFilter,
  inboxFilters,
  [theatresSlice.reducerPath]: theatresSlice.reducer,
  [cplApiSlice.reducerPath]: cplApiSlice.reducer,
  [movieSlice.reducerPath]: movieSlice.reducer,
  [usersSlice.reducerPath]: usersSlice.reducer,
  facilityDevices
})
