import { createApi } from '@reduxjs/toolkit/query/react'
import qwDimensions from '../common/client/qwDimensions'

const movieBaseQuery = async ({ id }) => {
  try {
    const result = await qwDimensions.get(`/v2/movies/${id}`)
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export const movieSlice = createApi({
  reducerPath: 'moviesApi',
  baseQuery: movieBaseQuery,
  serializeQueryArgs: args => args.queryArgs,
  keepUnusedDataFor: 28800,
  endpoints: builder => ({
    fetchMovie: builder.query({
      query: id => {
        return { id }
      }
    })
  })
})

export const { useFetchMovieQuery } = movieSlice
