import axios from 'axios'
import Qs from 'qs'
import { getAccessToken } from '../util/helper'
import { QW_KDM_REQUEST_URL } from '../config'

const qwUser = axios.create({
  baseURL: QW_KDM_REQUEST_URL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default qwUser
