import axios from 'axios'
import Qs from 'qs'
import { QW_DIMENSIONS_URL } from '../config'
import { getAccessToken } from '../util/helper'

const qwDimensions = axios.create({
  baseURL: QW_DIMENSIONS_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default qwDimensions
